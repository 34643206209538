import Constant from '@/const';

const axios = require('axios');

export default {
  namespaced: true,
  state: {
    client: {
      info: {
        name: '',
        email: '',
        hospitalType: '',
        longHospitalType: '',
        hospitalOrganization: '',
        address: {
          street: '',
          village: '',
          subdistrict: '',
          regency: '',
          province: '',
        },
        postCode: '',
        telephoneNo: '',
        faxNo: '',
        distinctGender: '',
        ppkService: '',
        useLetterBackground: false,
      },
      shortLogo: null,
      longLogo: null,
      a4BackgroundCertif: null,
      a4BackgroundPlain: null,
      legalBackgroundCertif: null,
      backgroundQueue: null,
      filledShortLogo: null,
      overcounterInsurance: null,
      typeSignatureRecipe: null,
      finishedRowColor: null,
      isUseLockPharmacy: null,
      outpatientBarcodePrint: {
        size: null,
        column: null,
        orientation: null,
      },
      resumeSignature: {
        value: '',
        description: '',
      },
      showDateActionConsumable: false,
      controlLetterRegistStatus: [],
      availableRoomClass: [],
      notePrintQueue: null,
      isShowSkippedQueue: null,
      queueDefaultMode: null,
      examinationTypes: [],
    },
  },
  getters: {
    clientData: (state) => state.client,
  },
  mutations: {
    setClientData(state, payload) {
      state.client[payload.key] = payload.data;
    },
    setDoubleKey(state, payload) {
      state.client[payload.key][payload.secondKey] = payload.data;
    },
  },
  actions: {
    async getClient({ commit }) {
      // get client info
      await axios
        .get(`${Constant.apiUrl}/configuration/clientinfo`)
        .then((response) => {
          const { data } = response.data;
          commit('setClientData', { key: 'info', data });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getOvercounterInsurance({ commit }) {
      // get client info
      await axios
        .get(`${Constant.apiUrl}/configuration/overcounterInsurance`)
        .then((response) => {
          const { data } = response.data;
          commit('setClientData', { key: 'overcounterInsurance', data });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getClientData() {
      // get client info
      return axios.get(`${Constant.apiUrl}/configuration/clientinfo`);
    },
    async getAllClientImage({ commit }) {
      const keyData = [
        {
          key: 'clientlogo-transparent-short',
          payload: 'shortLogo',
        },
        {
          key: 'clientlogo-transparent-long',
          payload: 'longLogo',
        },
        {
          key: 'clientletterbg-A4-cert',
          payload: 'a4BackgroundCertif',
        },
        {
          key: 'clientletterbg-A4-plain',
          payload: 'a4BackgroundPlain',
        },
        {
          key: 'clientletterbg-legal-cert',
          payload: 'legalBackgroundCertif',
        },
        {
          key: 'filledShortLogo',
          payload: 'clientlogo-filled-short',
        },
      ];

      keyData.forEach((item) => {
        axios
          .get(`${Constant.apiUrl}/configuration/fetch?key=${item.key}`)
          .then((response) => {
            const { data } = response.data;
            commit('setClientData', { key: item.payload, data: data[0] });
          })
          .catch((error) => {
            console.log('tesa error', error);
          });
      });
    },
    async getClientImage({ commit }, payloadKey) {
      let key;
      switch (payloadKey) {
        case 'shortLogo':
          key = 'clientlogo-transparent-short';
          break;
        case 'longLogo':
          key = 'clientlogo-transparent-long';
          break;
        case 'a4BackgroundCertif':
          key = 'clientletterbg-A4-cert';
          break;
        case 'a4BackgroundPlain':
          key = 'clientletterbg-A4-plain';
          break;
        case 'legalBackgroundCertif':
          key = 'clientletterbg-legal-cert';
          break;
        case 'filledShortLogo':
          key = 'clientlogo-filled-short';
          break;
        default:
          key = '';
          break;
      }
      axios
        .get(`${Constant.apiUrl}/configuration/fetch?key=${key}`)
        .then((response) => {
          const { data } = response.data;
          commit('setClientData', { key: payloadKey, data: data[0] });
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    async getBackgroundQueue({ commit }) {
      axios
        .get(
          `${Constant.apiUrl}/configuration/fetch?key=clientimage-background-queue`,
        )
        .then((response) => {
          const { data } = response.data;
          commit('setClientData', { key: 'backgroundQueue', data: data[0] });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getSizeBarcodeprint({ commit }) {
      axios
        .get(
          `${Constant.apiUrl}/configuration/fetch?key=clientconfig_printbarcode_size`,
        )
        .then((response) => {
          const { data } = response.data;
          commit('setDoubleKey', {
            key: 'outpatientBarcodePrint',
            secondKey: 'size',
            data: data[0].value,
          });
        });
    },
    async getColumnBarcodeprint({ commit }) {
      axios
        .get(
          `${Constant.apiUrl}/configuration/fetch?key=clientconfig_printbarcode_column`,
        )
        .then((response) => {
          const { data } = response.data;
          commit('setDoubleKey', {
            key: 'outpatientBarcodePrint',
            secondKey: 'column',
            data: data[0].value,
          });
        });
    },
    async getOrientationBarcodeprint({ commit }) {
      axios
        .get(
          `${Constant.apiUrl}/configuration/fetch?key=clientconfig_printbarcode_orientation`,
        )
        .then((response) => {
          const { data } = response.data;
          commit('setDoubleKey', {
            key: 'outpatientBarcodePrint',
            secondKey: 'orientation',
            data: data[0].value,
          });
        });
    },
    async getResumeSignature({ commit }) {
      axios
        .get(
          `${Constant.apiUrl}/configuration/fetch?key=clientconfig-outpatient-resume-signature`,
        )
        .then((response) => {
          const { data } = response.data;
          commit('setClientData', {
            key: 'resumeSignature',
            data: data[0].value,
          });
        });
    },
    async showDateActionConsumable({ commit }) {
      axios
        .get(
          `${Constant.apiUrl}/configuration/fetch?key=clientconfig-action-consumable-showdate`,
        )
        .then((response) => {
          const { data } = response.data;
          commit('setClientData', {
            key: 'showDateActionConsumable',
            data: data[0].value === 'true',
          });
        });
    },
    async getRecipeSignatureType({ commit }) {
      axios
        .get(
          `${Constant.apiUrl}/configuration/fetch?key=clientconfig-erecipe-signature`,
        )
        .then((response) => {
          const { data } = response.data;
          commit('setClientData', {
            key: 'typeSignatureRecipe',
            data: data[0].value,
          });
        });
    },
    async getOutpatientDashboardFinishedRowColor({ commit }) {
      axios
        .get(
          `${Constant.apiUrl}/configuration/fetch?key=clientconfig-outpatient-finish-row-color`,
        )
        .then((response) => {
          const { data } = response.data;
          commit('setClientData', {
            key: 'finishedRowColor',
            data: data[0].value,
          });
        });
    },
    async getUseLockPharmacy({ commit }) {
      axios
        .get(
          `${Constant.apiUrl}/configuration/fetch?key=clientconfig-pharmacy-lock`,
        )
        .then((response) => {
          const { data } = response.data;
          commit('setClientData', {
            key: 'isUseLockPharmacy',
            data: data[0].value === 'true',
          });
        });
    },
    async getControlLetterRegistStatus({ commit }) {
      axios
        .get(
          `${Constant.apiUrl}/configuration/fetch?key=clientconfig-controlletter-regist`,
        )
        .then((response) => {
          const { data } = response.data;
          commit('setClientData', {
            key: 'controlLetterRegistStatus',
            data: data[0].value,
          });
        });
    },
    async getAvailableRoomClass({ commit }) {
      axios
        .get(
          `${Constant.apiUrl}/configuration/fetch?key=client-config-available-room-class`,
        )
        .then((response) => {
          const { data } = response.data;
          commit('setClientData', {
            key: 'availableRoomClass',
            data: JSON.parse(data[0].value),
          });
        });
    },
    async getNotePrintQueue({ commit }) {
      axios
        .get(
          `${Constant.apiUrl}/configuration/fetch?key=clientconfig-note-print-queue`,
        )
        .then((response) => {
          const { data } = response.data;
          commit('setClientData', {
            key: 'notePrintQueue',
            data: data[0].value,
          });
        });
    },
    async getSkippedQueueConfiguration({ commit }) {
      try {
        const response = await axios.get(
          `${Constant.apiUrl}/configuration/fetch?key=clientconfig-show-skipped-queue`,
        );
        const { data } = response.data;
        commit('setClientData', {
          key: 'isShowSkippedQueue',
          data: data[0].value,
        });
      } catch (error) {
        console.log(error);
        commit('setClientData', {
          key: 'isShowSkippedQueue',
          data: 'true',
        });
      }
    },
    async getDefaultQueueMode({ commit }) {
      try {
        const response = await axios.get(
          `${Constant.apiUrl}/configuration/fetch?key=clientconfig-default-queue-mode`,
        );
        const { data } = response.data;
        commit('setClientData', {
          key: 'queueDefaultMode',
          data: data[0].value,
        });
      } catch (error) {
        console.log(error);
        commit('setClientData', {
          key: 'queueDefaultMode',
          data: 'automatic',
        });
      }
    },
    async getExaminationType({ commit }) {
      try {
        axios
          .get(
            `${Constant.apiUrl}/configuration/fetch?key=client-config-examination-types`,
          )
          .then((response) => {
            console.log(response);
            const { data } = response.data;
            commit('setClientData', {
              key: 'examinationTypes',
              data: JSON.parse(data[0].value),
            });
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
